
import instance from '@/utils/request.js'

// 客户行为分析
export function postForm(title) {
  return instance({
    url: '/portal/site/visit/log',
    method: 'post',
    data: { pageTitle: title }
  })
}
