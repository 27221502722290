<template>
	<div class="header f-x-b-c">
        <div class="title f-y-c-s">
            <span class="text1">{{text1}}</span>
            <span class="text2">{{text2}}</span>
        </div>
        <el-button @click="callback" type="text" class="more">{{text3}}</el-button>
    </div>
</template>
<script>
export default {
    props:{
        text1:{
            type:String,
            default:''
        },
        text2:{
            type:String,
            default:''
        },
        text3:{
            type:String,
            default:'MORE+ '
        }
    },
	data() {
		return {
           
        }
	},
    methods:{
        callback(){
            this.$emit('callback')
        }
    }
}
</script>
<style lang='less' scoped>

@media screen and (max-width: 899px) {
    .header{
        padding: 0 13px;
        .title{
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 100%;
            .text1{
                
                font-weight: bold;
                font-size: 24px;
                line-height: 24px;
                color: #1B1D22;
            }
            .text2{
                margin-top: 5px;
                
                font-weight: 400;
                font-size: 12px;
                color: #1B1D22;
            }
        }
        .more{
            display: none;
        }
    }
}
@media screen and (min-width: 900px) {
    .header{
        padding: 0 180px;
        // height: 120px;
        .title{
            display: flex;
            flex-flow: column;
            align-items: start;
            .text1{
                
                font-weight: bold;
                font-size: 60px;
                line-height: 60px;
                color: #1B1D22;
            }
            .text2{
                
                font-weight: 400;
                font-size: 30px;
                line-height: 30px;
                margin-top: 19px;
                color: #1B1D22;
            }
        }
        .more{
            /deep/ span{
                
                font-weight: 300;
                font-size: 30px;
                color: #666666;
            }
            
        }
    }
}

</style>
