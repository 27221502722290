<template>
    <div @click="nvgaToCaseDetail(data.id)" class="template-box" >
        <div v-if="type=='1'" class="template-item1 common f-y-b-s" 
            :style="`background-image:url(${data.coverUrl})`"
            >
            <div @click.stop="navToDesignDet(data.designerId)" class="header f-x-b-c">
                <div class="info f-x-s-c">
                    <div class="avtar">
                        <img class="img" :src="formatAvtar(data.designerId)" alt="">
                    </div>
                    <span class="name">{{data.designerName}}</span>
                </div>
                <div @click.stop="openDialog('price')" class="msg f-x-c-c">这样装修需要多少钱？</div>
            </div>
            <div class="bottom1 f-y-c-s">
                <div class="mask"></div>
                <div class="title" >{{data.villageName}}</div>
                <HouseInfo class="house" :data="data" type="row" textColor="#fff" ></HouseInfo>
            </div>
            <div class="bottom2 f-y-c-s">
                <div class="mask"></div>
                <div class="title" >{{data.villageName}}</div>
                <HouseInfo class="house" :data="data" type="row" textColor="#fff" :textSize="12"></HouseInfo>
            </div>
        </div>

        <!-- <div v-if="type=='2'" class="template-item2 common f-y-b-s"  -->
        <div v-if="type=='2'" class="template-item2 common" @mouseenter="hover = true" @mouseleave="hover = false">
            <div class="box">
                <img class="img1" :src="data.coverUrl" alt="">
            </div>
            
            <div class="header f-x-b-c">
                <div class="info f-x-s-c">
                    <div class="avtar">
                        <img class="img" :src="formatAvtar(data.designerId)" alt="">
                    </div>
                    <span class="name">{{data.designerName}}</span>
                </div>
                <!-- <transition
                    name="fade-move"
                > -->
                <div  @click.stop="openDialog('plan')" class="msg f-x-c-c">免费获取方案</div>
                <!-- </transition> -->
                
            </div>
            <!-- pc -->
            <div class="bottom1 f-y-c-s">
                <div class="title eps1" >{{data.villageName}}</div>
                <HouseInfo :data="data" type="row" textColor="#000" :textSize="26"></HouseInfo>
            </div>
            <!-- 移动 -->
            <div class="bottom2 f-y-c-s">
                <div class="title" >{{data.villageName}}</div>
                <HouseInfo :data="data" type="row" textColor="#000" :textSize="10"></HouseInfo>
            </div>
        </div>
        <DialogForm ref="refDialogForm" :adUnitCode="adUnitCode"></DialogForm>
    </div>
</template>
<script>
import {useDesDict} from '@/utils/frequent.js'
import HouseInfo from '../components/HouseInfo.vue'
export default {
    components:{
        HouseInfo
    },
    props:{
        data:{
            type:Object,
            default:()=>({})
        },
        type:{
            type:String,
            default:'1'
        },
        adUnitCode: {
			type: String,
            default:"XSdw"
		},
    },
    async mounted(){
        this.desList = await useDesDict()
    },
    data() {
        return {
            desList:[],
            hover:false
        }
    },
    methods:{
        navToDesignDet(id){
            this.$router.push({
                path:'/design/detail',
                query:{
                    id
                }
            })
        },
        openDialog(type){
            this.$refs.refDialogForm.showDialog(type)
        },
        nvgaToCaseDetail(id) {
            this.$router.push({
                path:'/investor/detail',
                query:{
                    id
                }
            })
        },
        formatAvtar(id){
            const res = this.desList.find(item=>item.id == id)
            if(res) return res.headerImgUrl
            // else return 
        }
    }

}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .template-box{
        width: 100%;
        height: 100%;
    }
    .common{
        // padding: 6px 0 20px 7px;
        box-sizing: border-box;
        width: 100%;
        background-repeat: no-repeat;
        .header{
            .info{
                .avtar{
                    width: 31px;
                    height: 31px;
                    border-radius: 50%;
                    border: 1px solid #FFFFFF;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-right: 5px;
                    .img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .name{
                    
                    font-weight: bold;
                    font-size: 13px;
                    color: #1B1D22;
                }
            }
            .msg{
                height: 21px;
                background: #753CBE;
                padding: 5px 11px 5px 13px;
                background: #753CBE;
                border-radius: 15px 0 0 15px;
                box-sizing: border-box;
                
                font-weight: 400;
                font-size: 11px;
                color: #FFFFFF;
            }
        }
        .bottom1{
            display: none;
        }
        .bottom2{
            display: block;
            width: 100%;
            position: relative;
            .mask{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
            }
            .title{
                position: relative;
                z-index: 9;
                width: 100%;
                
                font-weight: bold;
                
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .house{
                position: relative;
                z-index: 9;
            }
            .small{
                font-size: 10px;
                color: #000;
            }
        }
    }
    
    .template-item1{
        background-size: 100% 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        border-radius: 6px;
        .header{
            padding: 6px 0 20px 7px;
            width: 100%;
            box-sizing: border-box;
        }
        .bottom2{
            padding: 18px;
            .title{
                color: #FFFFFF;
                font-size: 18px;
            }
        }
    }
    .template-item2{
        display: flex;
        box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.08);
        border-radius: 3px;
        height: calc(100% - 15px);
        margin-bottom: 15px;
        
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-end;
        .box{
            width: 100%;
            height: 170px;
            overflow: hidden;
            overflow: hidden;
            border-radius: 3px 3px 0 0;
            .img1{
                width: 100%;
                height: 170px;
                transition: all 0.6s;
            }
            .img1:hover{
                transform: scale(1.1);
            }
        }
        .header{
            position: absolute;
            top: 6px;
            right: 0;
            .info{
                display: none;
            }
            .msg{
                width: 90px;
                height: 21px;
                background: #753CBE;
                padding: 5px 11px 5px 13px;
                background: #753CBE;
                border-radius: 15px 0 0 15px;
                box-sizing: border-box;
                
                font-weight: 400;
                font-size: 11px;
                color: #FFFFFF;
            }
        }
        .bottom2{
            padding: 22px 18px;
            box-sizing: border-box;
            .title{
                // color: #753CBE;
                color: #1B1D22;
                font-size: 15px;
            }
        }
    }
}
@media screen and (min-width: 900px) {

    .fade-move-enter-active,
    .fade-move-leave-active {
        // -webkit-transition: all .3s cubic-bezier(.55, 0, .1, 1);
        // transition: all .3s cubic-bezier(.55, 0, .1, 1)
        // transition: opacity 4s ease;
        transition: width 300s ease;
    }

    .fade-move-enter,.fade-move-leave-to{
        width: 0;
    }
    .fade-move-enter-to {
        width: 200px;
    }

    .template-box{
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    
    .common{
        
        box-sizing: border-box;
        width: 100%;
        background-repeat: no-repeat;
        position: relative;
        
        
        .header{
          
            .info{
                
                .avtar{
                    width: 82px;
                    height: 82px;
                    border-radius: 50%;
                    border: 2px solid #FFFFFF;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-right: 16px;
                    .img{
                        width: 100%;
                        height: 100%;
                    }
                    
                }
                .name{
                    
                    font-weight: bold;
                    font-size: 36px;
                    color: #1B1D22;
                }
            }
            .msg{
                white-space: nowrap;
                transition: all 1s;
                // display: none;
                overflow: hidden;
                
                // padding: 13px 25px 13px 29px;
                height: 56px;
                background: #753CBE;
                border-radius: 30px 0 0 30px;
                box-sizing: border-box;
                
                font-weight: 400;
                font-size: 30px;
                color: #FFFFFF;
            }
        }
        .bottom2{
            display: none;
        }
        .bottom1{
            display: block;
            width: 100%;
            padding: 0 48px;
            margin-top: 50px;
            box-sizing: border-box;
            position: relative;
            .mask{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
            }
            .title{
                position: relative;
                z-index: 9;
                width: 100%;
                
                font-weight: bold;
                
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .house{
                position: relative;
                z-index: 9;
            }
            .small{
                font-size: 40px;
                color: #000;
            }
        }
    }
    .template-item1:hover{
        background-size: 110% 110%;
    }
    .template-item1{
        transition:all 0.6s ;
        background-size: 100% 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
        border-radius: 6px;
        .header{
            padding: 20px 0 0 20px;
            width: 100%;
            box-sizing: border-box;
            .msg{
                width: 350px;
            }
        }
        .bottom1{
            padding-bottom: 50px;
            .title{
                color: #FFFFFF;
                font-size: 48px;
            }
        }
    }
    // .common:hover{
    //     .header{
    //         .msg{
    //             // display: flex !important;
    //             // display: block;
    //             width: 350px;
    //         }
    //     }
    // }
    .template-item2:hover{
        background-size: 110% 77%;
        .header{
            .msg{
                // display: flex !important;
                // display: block;
                // width: 220px;
                transform: translateX(-220px);
            }
        }
    }
    .template-item2{
        overflow: hidden;
        padding-bottom: 52px;
        transition:all 0.6s;
        display: flex;
        box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.08);
        border-radius: 6px;
        height: calc(100% - 15px);
        margin-bottom: 15px;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        .box{
            width: 100%;
            height: 460px;
            overflow: hidden;
            overflow: hidden;
            border-radius: 6px 6px 0 0;
            .img1{
                width: 100%;
                height: 460px;
                transition: all 0.6s;
            }
            .img1:hover{
                transform: scale(1.1);
            }
        }
        .header{
            position: absolute;
            left: 20px;
            top: 20px;
            width: calc(100% - 20px);

            .info{
                .avtar{
                    width: 72px;
                    height: 72px;
                }
                .name{
                    
                    font-weight: bold;
                    font-size: 30px;
                    color: #1B1D22;
                }
               
            }
            .msg{
                position: absolute;
                right: -220px;
                width: 220px;
                // transition: tra;
            }
        }
        .bottom1{
            .title{
                // color: #753CBE;
                color: #1B1D22;
                font-size: 40px;
            }
        }
    }
}

</style>
